<template>
  <div id="app">
    <router-view></router-view>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from './components/page-footer'
export default {
  components: {
    PageFooter
  },
  data() {
    return {
    }
  },
  // computed: {
  //   keepAliveArray: {
  //     get() {
  //       return this.$store.state.common.keepAliveArray
  //     },
  //     set(arr) {
  //       this.$store.commit('common/updateKeepAliveArray',arr)
  //     }
  //   }
  // },
  // watch: {
  //   $route (route) {
  //     let array = this.keepAliveArray || []
  //     if (route.meta.keepAlive && !array.includes(route.name)) {
  //       array.push(route.name)
  //     }
  //     this.keepAliveArray = array
  //   }
  // }
}
</script>
<style lang="less">
@import 'https://at.alicdn.com/t/c/font_3942476_wogtnjx4kjk.css';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-backtop {
  bottom: -80vh !important;
}
body {
  overflow-x: hidden;
  overflow-y: auto;
}
.infinite-list {
  padding-top: 90px;
}
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

/*element滚动条*/
.sys-zd .el-scrollbar__wrap {
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #409EFF;
    }
  }
}

.hide {
  display: none !important;
}

/*element滚动条*/
.sys-zd .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.sys-zd .el-scrollbar__thumb {
  background-color: rgba(255, 255, 255, .3) !important;
}
.back-btn {
  position: absolute;
  left: -50px;
  top: 0;
  width: 50px;
  padding: 20px 8px;
  background: #fff;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 9;
  .iconfont {
    font-size: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    right: -5px;
    top: 0;
    width: 6px;
    height: 100%;
    background: #fff;
    z-index: 9;
  }
}
</style>
