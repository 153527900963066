/**
 * 接口api常量类，统一管理，增加注释
 */
export const api = {
	'SYS': {
		// 获取短信验证码
		'VALID_CODE': '/sys/getSmsValidCode',
		// 登录
		'LOGIN': '/sys/login',
		// 手机号登录
		'MOBILE_LOGIN': '/sys/mobileLogin',
		// 手机端注册
		'MOBILE_REGISTER': '/sys/user/landingPageRegister',
		'USER': {
			'INFO': '/sys/user/info',
			'CHANGE_MOBILE': '/sys/user/mobile',
			'CHANGE_NICKNAME': '/sys/user/nickname',
			'REGISTER': '/sys/user/register',
			'FORGET_PASSWORD': '/sys/user/forgetPassword',
			'PASSWORD': '/sys/user/password',
			'HEAD_PATH': '/sys/user/headPath',
			'RELATION_ENTERPRISE': '/sys/user/relationEnterprise',
			'SAVE_SUPPORT': '/sys/user/saveSupport'
		},
		// 短信验证码接口
		'V_CODE': {
			// 手机号+短信验证码+数字验证码校验（注册用）
			'VERIFY': '/sys/vcode/verify'
		},
		'LOGOUT': '/sys/logout'
	},
	'STATS': {
		'TOTAL_DATA': '/stats/statstotal/getDataTotal'
	},
	// 字典
	'DICT': {
		'DICT_LIST': '/dict/findDictByIds',
		'DICT_MAP': '/dict/findDictMapByIds'
	},
	// 文件上传
	'UPLOAD': {
		'ENTERPRISE': '/upload/enterprise',
		'ORDER_FILE': '/upload/orderFile',
		'PAYMENT': '/upload/payment',
		'HEADPATH': '/upload/headPath',
		'VALUEPATH': '/upload/brandValue',
		'EVALUATEPATH': '/upload/brandValue'
	},
	'PUB': {
		'MENU': '/pubInfo/menu',
		'SERVICE_LIST': '/pubInfo/serviceList',
		'SERVICE_INFO': '/pubInfo/serviceInfo',
		'RECOMMEND_LIST': '/pubInfo/recommendList',
		'RECOMMEND_PRODUCT': '/pubInfo/recommendProduct',
		'ZRYT_PRODUCT': '/pubInfo/zrytProduct',
		'TOP_PRODUCT': '/pubInfo/topProduct',
		'CQC_PRODUCT': '/pubInfo/cqcProduct',
		'CF_ITEM': '/pubInfo/getCfItemList',
		'NEU_REPORT': '/pubInfo/getNeuReport',
		'BRAND_VALUE': '/pubInfo/brandValue',
		'CAROUSEL': '/pubInfo/carousel'
	},
	'TC': {
		'LIST': '/tcinfo/list',
		'SAVE': '/tcinfo/save'
	},
	'GB': {
		// 机构
		'ORG': {
			'PAGE': '/org/list',
			'FIND_CHILD_ORG': '/org/findChildOrg',
			'FIND_ORG': '/org/findOrgByCode',
			'INFO': '/org/findOrgByCode'
		}
	},
	'CF': {
		'SERVICE_ITEM': {
			'LIST': '/cf/serviceItem/list'
		}
	},
	'U': {
		// 企业用户信息
		'ENTERPRISE': {
			'LIST': '/u/enterprise/list',
			'INFO': '/u/enterprise/info',
			'SAVE': '/u/enterprise/save',
			'UPDATE': '/u/enterprise/update',
			'DELETE': '/u/enterprise/delete'
		},
		//服务商
		'SUPPORT': {
			'COUNT': '/u/supportinfo/count'
		}
	},

	// 消息中心
	'MESSAGE': {
		'LIST': '/remind/list',
		'UPDATE': '/remind/update',
		'DELETE': '/remind/delete'
	},
	'NEED': {
		'LIST': '/need/list',
		'SUBMIT': '/need/submit',
		'INFO': '/need/info',
		'DELETE': '/need/delete',
		'CLOSE': '/need/close'
	},
	'SOLUTION': {
		'LIST': '/solution/list',
		'INFO': '/solution/info',
		'AGREE': '/solution/agree',
		'REJECT': '/solution/reject'
	},
	'FEEDBACK': {
		'SAVE': '/pub/feedback/save'
	},
	// 订单评论
	'ORDER_COMMENT': {
		// 新增用户评论
		'SAVE': '/service/usercomment/saveServiceUserComment',
		// 根据订单id查询用户评价
		'ID_INFO': '/service/usercomment/infoByOrderId',
		// 根据服务产品id查询用户评价列表
		'ID_LIST' :'/service/usercomment/queryPageByServiceId',
		// 查询所有用户评价
		'LIST': '/service/usercomment/list',
		// 新增服务商对用户评价（回评）,
		'SERVER_SAVE': '/service/supportcomment/saveServiceSupportComment',
		// 变更用户评论‘是否屏蔽’
		'IS_HIDE': '/service/usercomment/isHide'
	},
	// 推荐试服务
	'RECOMMENDED':{
		// 公司手机号认证
		'SUBMIT':'/bs/bsrecommend/checkRecommend',
		// 获取推荐信息
		'LIST':'/bs/bsrecommend/getRecommend',
		//返回fini 获取推荐
		'STATS':'/bs/bsrecommend/getRecommendStats'
	},
	// 质量在线诊断
	'QUALITY_DOCTOR': {
		//  查询医生列表
		'DOCTOR_LIST': '/bs/doctor/info/list',
		// 查询所有质量医生
		'ALL_DOCTOR_LIST': '/bs/doctor/info/listAllDoctor',
		//  查询消息成员列表
		'MESSAGE_LIST': '/bs/bsroommsg/msgPage',
		//  上传聊天图片
		'UPLOAD_IMAGE': '/upload/msgImage',
		//  发送消息
		'SEND_MESSAGE': '/wsim/{fromId}/{toId}',
		//  聊天记录
		'CHAT_RECORD': '/bs/bsroommsg/chatRecordPage',
		// 获取一个行业的在线医生
		'RANDOM_DOCTOR': '/bs/doctor/info/randomDoctor',
		// 获取用户总的--未读消息数量
		'MESSAGE_NUMBER': '/bs/bsroommsg/noreadCount',
		//  查询消息成员列表--(专家使用)
		'MESSAGE_LIST_EXPERT': '/bs/room/msg/msgPage',
		//  聊天记录--专家聊天记录（专家使用）
		'CHAT_RECORD_EXPERT': '/bs/room/msg/chatRecordPage',
		//  查询微信公众号配置信息
		'WECHAT_INFO': '/sys/wechat/config/info',
		//  获取微信授权登录openid，token
		'WECHAT_TOKEN': '/sys/login/getTokenByWechat',
		//  微信登录
		'WECHAT_LOGIN': '/sys/wechat/login'
	},
	// 发票管理
	'INVOICE': {
		//  查询收货地址
		'ADDRESS_LIST': '/user/address/queryPage',
		// 新增收票地址
		'ADD_ADDRESS': '/user/address/save',
		//  修改收货地址
		'UPDATE_ADDRESS': '/user/address/update',
		//  删除收票地址
		'DELETE_ADDRESS': '/user/address/delete',
		//  查询抬头列表
		'RISE_LIST': '/user/invoice/header/listByType',
		//  新增发票抬头
		'RISE_SAVE': '/user/invoice/header/save',
		//  修改发票抬头
		'RISE_UPDATE': '/user/invoice/header/update',
		//  删除发票抬头
		'RISE_DELETE': '/user/invoice/header/delete',
		//  查询增值资质信息
		'QUALIFICATION_INFO': '/user/invoice/qualification/info',
		//  新增或修改增值资质信息
		'UPDATE_QUALIFICATION': '/user/invoice/qualification/saveOrUpdate',
		// 上传发票授权文件
		'UPLOAD_AUTHORIZE': '/upload/invoiceAuthorizationImage',
		//  开票申请（已完成的订单）
		'INVOICE_SAVE': '/order/invoice/save',
		//  重新提交开票申请（已完成的订单）
		'INVOICE_AGAIN': '/order/invoice/resave',
		//  开票申请分页列表
		'INVOICE_LIST': '/order/invoice/queryPage'
	},
	//咨询
	'CONSULT':{
		//分页查询
		'LIST':'/bs/businessConsult/queryPage',
		//根据用户Id查询
		'USER_ID_LIST':'/bs/businessConsult/queryPageByUserId',
		//根据数据Id查询
		'ID_LIST':'/bs/businessConsult/getById',
		//新增
		'SAVE':'/bs/businessConsult/saveBsBusinessConsult',
		//修改
		'UPDATE':'/bs/businessConsult/updateBsBusinessConsult',
		//删除
		'DELETE':'/bs/businessConsult/removeByIds',
		// 一站式服务
		'ONE_STOP' :'/bs/bsonestopconsult/saveOneStopPhone'
	},
// /pubInfo/slideCheck   POST  params
	'SLIDE':{
		'CHECK':'/pubInfo/slideCheck'
	},
	// 新闻
	'NEWS': {
		'LIST': '/pubInfo/news/list',
		'INFO': '/pubInfo/news/info'
	},
	// 通知
	'NOTICE': {
		'LIST': '/pubInfo/notice/list',
		'INFO': '/pubInfo/notice/info'
	},
	// 我的订单
	'ORDER': {
		'LIST': '/order/list',
		'TRENDS': '/pubInfo/getOrderTrends',
		'SAVE': '/order/save',
		'NEU_ORDER': '/order/neuOrder',
		'INFO': '/order/info',
		'FILE': '/order/uploadOrderFile',
		'PAY': '/order/uploadPay',
		'PAY_CONFIRM': '/order/payConfirm',
		'DELETE': '/order/delete',
		'ORDER_COUNT': '/order/orderCount',
		'REFUND_APPLY': '/order/refundApply',
		'PAY_STATUS': '/order/getPayStatus',
		'PAY_CODE': '/bs/payuser/payCode',
		'PLACE_ORDER_WX':'/service/order/pay/placeOrder',
		'CHECK_LIST_WX':'/wechat/native/order/queryOrder',
		'CLOSE_LIST_WX': '/wechat/native/order/closeOrder'

	},
	// 产品
	'PRODUCT': {
		'SERVICE': {
			'LIST': '/product/serviceInfo/list',
			'SOLRLIST': '/product/serviceInfo/solrList',
			'SEARCH': '/product/serviceInfo/searchService',
			'INFO': '/product/serviceInfo/info'
		}
	},
	//购物车
	'SHOPPING_CART': {
		//购物车列表   >>>>>暂无数据
		'LIST': '/shoppingCart/list',
		//保存一个购物车信息
		'SAVE': '/shoppingCart/save',
		//根据ID删除购物车信息
		'DELETE': '/shoppingCart/delete',
		//更新购物车信息
		'UPDATE': '/shoppingCart/update',
		//清空购物车
		'DELETE_ALL': '/shoppingCart/deleteAll'
	},
	'COLLECT': {
		'LIST': '/collect/list',
		'SEARCH': '/collect/search',
		'SAVE': '/collect/save',
		'DELETE': '/collect/delete',
		'REMOVE': '/collect/remove'
	},
	// 通知
	'TECH': {
		'WORD2VEC': {
			'LIST': '/techword2vec/list',
			'SAVE': '/techword2vec/save',
			'INFO': '/techword2vec/info',
			'NEAREST': '/techword2vec/getNearest'
		},
		'BRAND': {
			'LIST': '/techbrandvalue/list',
			'SAVE': '/techbrandvalue/save',
			'INFO': '/techbrandvalue/info',
			'NEAREST': '/techbrandvalue/getNearest'
		},
		'CQC': {
			'LIST': '/cqc/list'
		},
		'CREDIT': {
			'SAVE': '/techcredit/save'
		},
		'SELF_CREDIT': {
			'SAVE': '/techcreditself/save',
			'INFO': '/techcreditself/info'
		}
	},
	'VIEW': {
		'LIST': '/view/list',
		'SAVE': '/view/save',
		'DELETE': '/view/delete'
	},
	'QCC': {
		'ENT_INFO': {
			'INFO': '/qcc/getEnterpriseInfo'
		},
		'ENT_PRODUCT': {
			'PRODUCT_LIST': '/qcc/getProduct'
		},
		'NEWS': {
			'LIST': '/qcc/searchNews',
			'RATIO': '/qcc/getNewsImpactRatio'
		},
		'SEARCH':{
			'COMMON': '/product/serviceInfo/searchCommonParam',
		},
		// 经营状况
		'PERFORMANCE': {
			'INFO': '/qcc/getPerformance'
		},
		// 企业知识产权
		'KNOWLEDGE': {
			'INFO': '/qcc/getIPRInfo'
		},
		// 企业风险
		'RISK': {
			'INFO': '/qcc/getEnterpriseRiskInfo',
		},
		// 企业信用评价
		'CREDIT': {
			'INFO': '/qcc/getCreditInfo'
		}
	},
	'CQC': {
		'SAVE': '/cqcquestionnaire/save'
	},
	// 企业自我评价
	'EVALUATION': {
		// 已开通区域
		'ENABLED_AREA': '/evaluation/selfevaluationorgcode/enabledArea',
		// 专精特新类
		'ZJTX': {
			// 认定条件
			'CONDITIONS': '/evaluation/selfevaluationconditionszjtx/allConditions',
			// 指标分组及具体指标
			'INDICATOR_GROUPS': '/evaluation/selfevaluationindicatorgroupzjtx/groups',
		},
		// 专精特新小巨人
		'XIAOJUREN': {
			'GROUPS': '/evaluation/selfevaluationxiaojurenindicatorgroup/list'
		},
		'CREATIVE': {
			'CONDITIONS': '/evaluation/selfevaluationcreativecondition/list',
			'INDICATOR_GROUPS': '/evaluation/selfevaluationcreativeindicatorgroup/groups'
		}
	}
}
