<template>
  <div class="page-footer uf uf-ver uf-pc uf-ac">
    <div class="uf uf-ac">
      <img src="../assets/img/logo-sp-white.png" width="35">
      <div class="f18 ml-3">数据安全共享平台</div>
    </div>
    <div class="mt-2 op-05 f12">
        © 2023-2025 版权所有 数据安全共享平台
        <a style="color:#FFF; text-decoration:none" href="https://beian.miit.gov.cn/" target="_blank">  鲁ICP备2023017334号-1</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'page-footer',
  data() {
    return {
      // symbole: <script src='https://dcs.conac.cn/js/16/000/0000/61055564/CA160000000610555640001.js' type='text/javascript'/>
    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .page-footer {
    background: #000000;
    padding: 40px 0;
    color: white;
  }
</style>
