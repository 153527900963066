/**
 * 常量类
 */
export const cons = {
  // 域名
  'SERVER_PATH': process.env.VUE_APP_SERVER_URL,
  // 接口地址
  'API_SERVER_PATH': process.env.VUE_APP_BASE_URL,
  // 默认主页
  'HOME_PAGE': process.env.VUE_APP_HOME_PAGE,
  //技术构件地址
  'MODEL_PATH': process.env.VUE_APP_MODEL_URL,
  // 技术服务baseUrl
  'TEC_BASE_URL': 'http://www.zykjfw.cn:18083/',
  // 企业自评价-质量管理水平指标
  'SELF_EVALUATION_QUALITY_LEVEL': 'qualityLevel',
  // 企业自评价-指标-从事特定细分市场的时间
  'SELF_EVALUATION_WORKING_TIME': 'numberOfYear',
  // 企业自评价-特色化指标-山东省需要特殊处理的指标
  'SELF_EVALUATION_SD_INDICATOR': 'creativeProduct',
  // 专精特新类服务
  'ZJTX_SERVICE_ITEM_CODE': '900204',
  // 自评价类型
  'EVALUATION_TYPE': {
    'ZJTX': 'ZJTX',
    'XIAOJUREN': 'XIAOJUREN'
  }
}
